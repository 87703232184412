// src/pages/HomePage.tsx
import { ArrowRight, Settings, Database, Target, BarChart } from 'lucide-react'
import ReactGA from 'react-ga4'
import { Link } from 'react-router-dom'
import { BrandName } from '../components/BrandName'  


export default function HomePage() {
  const trackCTAClick = () => {
    ReactGA.event({
      category: 'CTA',
      action: 'click',
      label: 'Contact'
    })
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero Section */}
      <section className="bg-background-light py-24">
      {/* <div className="absolute right-0 top-1/2 -translate-y-1/2 opacity-10 hidden lg:block">
    <img 
      src="/Logo-Inverted.svg" 
      alt="TrueSpoke Icon"
      // className="w-96 h-96"
      className="w-24 h-24 mb-8 animate-spin-slow"
    />
  </div> */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-8 text-text-dark">
              Aligning your spokes to the{' '}
              <span className="text-accent-primary">AI revolution</span>
            </h1>
            <p className="text-xl md:text-2xl mb-10 max-w-3xl mx-auto text-text-dark/80">
              We help businesses leverage artificial intelligence to drive innovation,
              efficiency, and growth in the digital age, backed by 18+ years of enterprise 
              data leadership.
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center bg-accent-primary text-white px-8 py-4 rounded-lg font-semibold hover:bg-accent-hover transition-all transform hover:scale-105"
              onClick={trackCTAClick}
            >
              Schedule a Consultation <ArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </section>

      {/* Brand Story Section */}
      <section className="py-20 bg-background-primary">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-text-primary">
            Why <BrandName /> ?
            </h2>
            <p className="text-lg text-text-secondary max-w-3xl mx-auto">
              Just as a wheel's true spokes provide strength and stability, 
              your data serves as the critical support for AI initiatives. 
              At <BrandName />, we ensure your data framework is precisely aligned, 
              balanced, and optimized - creating a foundation for reliable, 
              effective AI solutions that drive your business forward.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-background-secondary">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-16 text-text-primary">
            How We Drive <span className="text-accent-primary">Value</span>
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                icon: Database,
                title: 'Data Engineering & Analytics',
                description: 'Enterprise-grade data solutions that form the foundation of your analytics and AI initiatives. Expertise in building scalable data architectures and comprehensive analytics frameworks.'
              },
              {
                icon: Settings,
                title: 'ML Operations Advisory',
                description: 'Strategic guidance for your data science teams to optimize ML workflows and identify operational improvements, with access to specialized MLOps implementation partners when needed.'
              },
              {
                icon: BarChart,
                title: 'Data Strategy & Solutions',
                description: 'Transformative data solutions that deliver tangible business value, from startup scale to Fortune 100 complexity. Expert in optimizing data modeling for comprehensive analytics.'
              },
              {
                icon: Target,
                title: 'AI Implementation',
                description: 'Strategic AI adoption and implementation, ensuring your data foundation supports reliable, efficient AI operations and insights.'
              }
            ].map((service, index) => (
              <div
                key={index}
                className="card p-8 hover:transform hover:scale-105 transition-all duration-300"
              >
                <service.icon className="w-12 h-12 text-accent-primary mb-6" />
                <h3 className="text-xl font-semibold mb-4 text-text-primary">
                  {service.title}
                </h3>
                <p className="text-text-secondary">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Results Section */}
      <section className="py-20 bg-background-primary">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-16 text-text-primary">
            Proven <span className="text-accent-primary">Results</span>
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                metric: '80+',
                description: 'Large-scale consulting projects delivered for startups to Fortune 100 companies'
              },
              {
                metric: '$2.2M+',
                description: 'Revenue generated through Data & AI solutions'
              },
              {
                metric: '90%',
                description: 'Reduction in ML model deployment time for enterprise clients'
              }
            ].map((result, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-bold text-accent-primary mb-4">
                  {result.metric}
                </div>
                <p className="text-text-secondary">{result.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-background-tertiary py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-bold mb-6 text-text-primary">
            Ready to Transform Your Data & AI Future?
          </h2>
          <p className="text-xl mb-10 max-w-2xl mx-auto text-text-secondary">
            Let's discuss how <BrandName />  can help align your data foundation and AI initiatives 
            for maximum impact.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center bg-accent-primary text-white px-8 py-4 rounded-lg font-semibold hover:bg-accent-hover transition-all transform hover:scale-105"
            onClick={trackCTAClick}
          >
            Schedule a Consultation <ArrowRight className="ml-2" />
          </Link>
        </div>
      </section>
    </div>
  )
}