// src/pages/AboutPage.tsx

import { Linkedin, Mail, FileText, ChevronLeft, ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { useState, useRef } from 'react'



interface Testimonial {
  content: string
  author: string
  title: string
  company: string
  linkedinUrl?: string
  date?: string
  imageUrl?: string 
}

// interface Experience {
//   title: string
//   company: string
//   period: string
//   description: string
//   technologies: string[]
//   logoUrl?: string 
// }

interface CompanyAccomplishment {
  category: 'Business Impact' | 'AI/ML Solutions' | 'Data Strategy' | 'Client Success';
  description: string;
  metrics?: string[];
}

interface CompanyExperience {
  company: string
  logoUrl: string
  tagline: string
  accomplishments: CompanyAccomplishment[]
  technologies: string[]
}

interface CarouselProps {
  companies: CompanyExperience[]
  selectedCompany: CompanyExperience | null
  onSelectCompany: (company: CompanyExperience) => void
}

const CompanyCarousel: React.FC<CarouselProps> = ({
  companies,
  selectedCompany,
  onSelectCompany
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const scrollTo = (direction: 'left' | 'right') => {
    if (carouselRef.current) {
      const cardWidth = 280; // Width of card + gap
      const currentScroll = carouselRef.current.scrollLeft;
      const newPosition = direction === 'left' 
        ? currentScroll - cardWidth
        : currentScroll + cardWidth;
      
      carouselRef.current.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
    }
  };

  // Mouse drag handlers
  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current!.offsetLeft);
    setScrollLeft(carouselRef.current!.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current!.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current!.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="relative">
      {/* Left Navigation Button */}
      <button
        onClick={() => scrollTo('left')}
        className="absolute left-0 top-1/2 -translate-y-1/2 z-10
          p-3 rounded-full bg-background-secondary hover:bg-background-tertiary
          transition-all duration-300 transform hover:scale-110
          focus:outline-none focus:ring-2 focus:ring-accent-primary"
      >
        <ChevronLeft className="text-text-primary w-6 h-6" />
      </button>

      {/* Carousel Container */}
      <div 
        ref={carouselRef}
        className="flex gap-6 overflow-x-auto scrollbar-hide py-4 px-16
          cursor-grab active:cursor-grabbing"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {companies.map((company, index) => (
          <div
            key={index}
            className={`flex-shrink-0 w-64 transition-all duration-300
              ${selectedCompany?.company === company.company
                ? 'scale-105 shadow-xl z-10 bg-background-tertiary'
                : 'opacity-50 hover:opacity-75 scale-95'
              }`}
            onClick={() => onSelectCompany(company)}
          >
            <div className="card p-6 cursor-pointer transition-all duration-300
              hover:transform hover:translate-y-[-4px] hover:shadow-lg
              active:transform active:translate-y-[-2px] active:shadow-md">
              {/* Company Logo */}
              <div className="h-20 bg-white rounded-lg p-3 mb-4 
                flex items-center justify-center
                transform transition-transform duration-300
                group-hover:scale-105">
                <img
                  src={company.logoUrl}
                  alt={company.company}
                  className="w-full h-full object-contain"
                />
              </div>

              {/* Company Info */}
              <h3 className="font-semibold text-text-primary mb-2 
                transition-colors duration-300
                group-hover:text-accent-primary">
                {company.company}
              </h3>
              <p className="text-sm text-text-secondary 
                transition-colors duration-300">
                {company.tagline}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Right Navigation Button */}
      <button
        onClick={() => scrollTo('right')}
        className="absolute right-0 top-1/2 -translate-y-1/2 z-10
          p-3 rounded-full bg-background-secondary hover:bg-background-tertiary
          transition-all duration-300 transform hover:scale-110
          focus:outline-none focus:ring-2 focus:ring-accent-primary"
      >
        <ChevronRight className="text-text-primary w-6 h-6" />
      </button>

      {/* Dot Indicators */}
      <div className="flex justify-center gap-2 mt-6">
        {companies.map((company, index) => (
          <button
            key={index}
            onClick={() => onSelectCompany(company)}
            className={`transition-all duration-300 rounded-full
              ${selectedCompany?.company === company.company
                ? 'w-8 h-2 bg-accent-primary'
                : 'w-2 h-2 bg-text-secondary hover:bg-accent-primary/50'
              }`}
            aria-label={`Select ${company.company}`}
          />
        ))}
      </div>
    </div>
  );
};


const AccomplishmentsDisplay: React.FC<{ company: CompanyExperience }> = ({ company }) => {
  return (
    <div className="mt-12 animate-fadeIn">
      <div className="grid md:grid-cols-2 gap-6">
        {company.accomplishments.map((accomplishment, index) => (
          <div key={index} className="card p-6">
            <div className="flex items-center mb-4">
              <div className="w-2 h-2 bg-accent-primary rounded-full mr-2" />
              <h4 className="font-semibold text-text-primary">{accomplishment.category}</h4>
            </div>
            <p className="text-text-secondary mb-4">{accomplishment.description}</p>
            {accomplishment.metrics && (
              <ul className="space-y-2">
                {accomplishment.metrics.map((metric, metricIndex) => (
                  <li key={metricIndex} className="text-sm text-accent-primary">
                    • {metric}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <div className="mt-6">
        <h4 className="text-sm font-medium text-text-secondary mb-2">Technologies</h4>
        <div className="flex flex-wrap gap-2">
          {company.technologies.map((tech, index) => (
            <span
              key={index}
              className="px-3 py-1 bg-background-tertiary rounded-full text-sm text-accent-primary"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}


export default function AboutPage() {
  // Existing state...
  const [selectedCompany, setSelectedCompany] = useState<CompanyExperience | null>(null);

  const trackResumeDownload = () => {
    ReactGA.event({
      category: 'Profile',
      action: 'download_resume',
      label: 'Resume Download'
    })
  }

  const trackLinkedInClick = () => {
    ReactGA.event({
      category: 'Profile',
      action: 'click_linkedin',
      label: 'LinkedIn Profile'
    })
  }

  
  const companyExperiences: CompanyExperience[] = [
    {
      company: "nCino",
      logoUrl: "/logos/ncino_inc__logo.jpeg",
      tagline: "Enterprise AI Solutions for Financial Services",
      accomplishments: [
        {
          category: "Business Impact",
          description: "Driving Revenue Through AI Innovation",
          metrics: [
            "Generated $900K annual revenue from AI solutions",
            "Reduced ML deployment costs by 90%",
            "Streamlined operations across global teams"
          ]
        },
        {
          category: "AI/ML Solutions",
          description: "Enterprise-Scale AI Implementation",
          metrics: [
            "Built generative AI platform for financial institutions",
            "Developed automated ML model deployment pipeline",
            "Created predictive analytics frameworks"
          ]
        },
        {
          category: "Data Strategy",
          description: "Scalable Data Architecture",
          metrics: [
            "Designed enterprise-wide AI/ML infrastructure",
            "Implemented robust data preprocessing systems",
            "Built production-grade benchmarking analytics"
          ]
        },
        {
          category: "Client Success",
          description: "Strategic Leadership & Delivery",
          metrics: [
            "Led global AI initiatives with C-suite alignment",
            "Managed cross-functional team of 15 engineers",
            "Delivered solutions for multiple business units"
          ]
        }
      ],
      technologies: ["AWS SageMaker", "Databricks", "MLFlow", "LLMs", "Neural Networks"]
    },
    {
      company: "Bytecode IO",
      logoUrl: "/logos/bytecode_io_logo.jpeg",
      tagline: "Data & Analytics Consulting Excellence",
      accomplishments: [
        {
          category: "Business Impact",
          description: "Revenue Growth & Cost Optimization",
          metrics: [
            "Drove $1.2M revenue through consulting projects",
            "Scaled consulting team from 20 to 65 members",
            "Reduced client reporting costs by 40%"
          ]
        },
        {
          category: "AI/ML Solutions",
          description: "Custom Analytics Solutions",
          metrics: [
            "Built predictive analytics systems for Fortune 100 clients",
            "Developed streaming analytics solutions",
            "Implemented automated data quality frameworks"
          ]
        },
        {
          category: "Data Strategy",
          description: "Enterprise Data Architecture",
          metrics: [
            "Led major data governance initiatives",
            "Designed cloud migration strategies",
            "Built scalable BI infrastructures"
          ]
        },
        {
          category: "Client Success",
          description: "Project Leadership & Delivery",
          metrics: [
            "Managed 80+ enterprise-level projects",
            "Led team of 18 senior consultants",
            "Delivered solutions for Electronic Arts and other major clients"
          ]
        }
      ],
      technologies: ["AWS", "GCP", "Snowflake", "Looker", "Python", "SQL"]
    },
    {
      company: "USAA",
      logoUrl: "/logos/usaa_logo.jpeg",
      tagline: "Enterprise Risk Analytics Innovation",
      accomplishments: [
        {
          category: "Business Impact",
          description: "Risk Management & Efficiency",
          metrics: [
            "Automated tracking for 2M+ vulnerabilities",
            "Reduced assessment time by 60%",
            "Streamlined security patch management"
          ]
        },
        {
          category: "AI/ML Solutions",
          description: "Automated Intelligence Systems",
          metrics: [
            "Built predictive risk assessment models",
            "Developed automated tracking algorithms",
            "Created real-time alert systems"
          ]
        },
        {
          category: "Data Strategy",
          description: "Enterprise Reporting Solutions",
          metrics: [
            "Designed executive analytics dashboards",
            "Implemented cross-platform data integration",
            "Created automated reporting frameworks"
          ]
        },
        {
          category: "Client Success",
          description: "Strategic Implementation",
          metrics: [
            "Delivered insights to executive leadership",
            "Improved technology upgrade planning",
            "Enhanced security communication systems"
          ]
        }
      ],
      technologies: ["Python", "SQL", "Tableau", "Risk Analytics"]
    },
    
    {
      company: "Earth Class Mail",
      logoUrl: "/logos/earth_class_mail_logo.jpeg",
      tagline: "SaaS Analytics Transformation",
      accomplishments: [
        {
          category: "Business Impact",
          description: "Operational Excellence",
          metrics: [
            "Reduced reporting time from 4 days to 2 hours",
            "Improved ROI across multiple brands",
            "Optimized forecasting for 15,000 customers"
          ]
        },
        {
          category: "AI/ML Solutions",
          description: "Predictive Analytics",
          metrics: [
            "Built advanced forecasting models",
            "Implemented customer behavior analytics",
            "Developed automated reporting systems"
          ]
        },
        {
          category: "Data Strategy",
          description: "SaaS Analytics Framework",
          metrics: [
            "Aligned analytics with SaaS metrics",
            "Created executive dashboards",
            "Implemented multi-brand tracking"
          ]
        },
        {
          category: "Client Success",
          description: "Executive Partnership",
          metrics: [
            "Partnered directly with CEO",
            "Led cross-functional analytics initiatives",
            "Delivered actionable business insights"
          ]
        }
      ],
      technologies: ["SaaS Analytics", "Business Intelligence", "Forecasting"]
    },
    
    {
      company: "Rackspace",
      logoUrl: "/logos/rackspace_technology_logo.jpeg",
      tagline: "Data Science & Analytics Leadership",
      accomplishments: [
        {
          category: "Business Impact",
          description: "Revenue & Efficiency Gains",
          metrics: [
            "Increased sales by 5% through ML recommendations",
            "Spearheaded automation delivering $85K/5-year savings",
            "Reduced customer churn significantly"
          ]
        },
        {
          category: "AI/ML Solutions",
          description: "Advanced ML Implementation",
          metrics: [
            "Built product recommendation system",
            "Developed customer retention prediction model",
            "Created real-time usage analytics"
          ]
        },
        {
          category: "Data Strategy",
          description: "Enterprise Data Solutions",
          metrics: [
            "Built Cloud Sales data warehouse",
            "Designed real-time tracking systems",
            "Implemented predictive forecasting"
          ]
        },
        {
          category: "Client Success",
          description: "Strategic Leadership",
          metrics: [
            "Led data science initiatives",
            "Built and managed data teams",
            "Partnered with Bain for market insights"
          ]
        }
      ],
      technologies: ["Machine Learning", "Random Forest", "Cloud Computing", "Data Warehousing", "QlikView"]
    }
    // Add similar blocks for USAA, Earth Class Mail, and Rackspace...
  ];

  const testimonials: Testimonial[] = [
    
    {
      content: `Mark and I were peers at ncino managing platform teams for AI and data respectively. This was early days of AI at 
      ncino and Mark was leading/managing the teams that were building out the infrastructure for AI and ML. His meticulous approach, 
      prior experience with consulting and data science helped us develop a world class infrastructure AI/ML platform. In Mark, the team 
      also benefited with a very hands on engineer who loved to build tools and utilities to help out with projects and also a great mentor 
      for junior engineers`,
      author: "Nithin RJ",
      title: "Data & AI Engineering",
      company: "nCino",
      linkedinUrl: "https://www.linkedin.com/in/nithinrj/",
      date: "September 2, 2024",
      imageUrl: "/images/nithin.jpeg"
    },
    {
      content: `I had the pleasure of working with Mark while he served as a Senior Manager of AI Platform at nCino, where he 
      reported directly to me. Mark consistently demonstrated an ability to lead and manage complex AI initiatives with a clear 
      vision and strategic approach.

      One of Mark’s most impactful contributions was his implementation of the Entrepreneurial Operating System (EOS) within my 
      leadership team. His deep understanding of this management framework not only helped streamline our processes but also
      dramatically improved our organization’s focus and output. Thanks to Mark's efforts, our team became more focused, organized, 
      and our decision-making became significantly more efficient and impactful.

      I highly recommend Mark for any future leadership roles, and I would be happy to work with him again.`,
      author: "Justin Norwood",
      title: "Chief Risk Officer",
      company: "Casca",
      linkedinUrl: "https://www.linkedin.com/in/justinnorwood/",
      date: "August 24, 2024",
      imageUrl: "/images/justin.jpeg"

    }
  ]

  // const experiences: Experience[] = [
  //   {
  //     title: "Senior Manager, Engineering - Data & AI",
  //     company: "nCino",
  //     period: "Dec 2022 - July 2024",
  //     description: "Led three high-performing technology teams focused on AI, data engineering, and analytics, managing 15 direct reports while co-owning global strategic AI and machine learning initiatives. Significantly improved operational efficiency by reducing machine learning model deployment time from 10 months to one through robust data preprocessing and architectural improvements. Established a new generative AI platform team that generated $900K in annual revenue by developing solutions for financial institutions. Successfully built and scaled the Benchmarking Analytics and Reporting team, which productionized industry benchmarks and insights products. Worked closely with C-suite executives to secure funding and align business strategy for enterprise AI initiatives, while ensuring adherence to ethical standards and best practices in AI development.",
  //     technologies: [
  //       "AWS SageMaker",
  //       "Databricks",
  //       "MLFlow",
  //       "CI/CD",
  //       "Natural Language Processing",
  //       "LLMs",
  //       "Neural Networks"
  //     ],
  //     logoUrl: "/logos/ncino_inc__logo.jpeg"
  //   },
  //   {
  //     title: "Senior Data & Analytics Consultant Manager",
  //     company: "Bytecode IO",
  //     period: "Apr 2021 - Dec 2022",
  //     description: "Led a team of 18 senior consultants while scaling the overall consultant team from 20 to 65 members in under 18 months. Spearheaded large-scale data governance and business intelligence initiatives using Looker, supporting major clients including Electronic Arts. Managed 80+ developers and conducted bi-weekly training sessions while maintaining 75% of regular senior consultant workload. Successfully built and demonstrated a customized demo at a national conference for Firebolt, implementing a complex data migration of over 2TB of data and 300+ files to AWS S3 with enhanced security measures.",
  //     technologies: [
  //       "Looker",
  //       "Python",
  //       "AWS S3",
  //       "MFA",
  //       "LookML",
  //       "SQL"
  //     ],
  //     logoUrl: "/logos/bytecode_io_logo.jpeg"
  //   },
  //   {
  //     title: "Senior Data & Analytics Consultant",
  //     company: "Bytecode IO",
  //     period: "Jan 2020 - Apr 2021",
  //     description: "Drove $1.2M in revenue through leadership of 80+ large-scale consulting projects for clients ranging from startups to Fortune 100 companies. Specialized in creating and maintaining cloud-based data analysis pipelines, implementing innovative solutions for video streaming analytics, and executing complex data migrations. Developed groundbreaking processes for analyzing video streaming interruptions, enabling early problem detection and improved service quality. Successfully migrated data for 15+ clients across major cloud platforms while ensuring strict data privacy compliance.",
  //     technologies: [
  //       "AWS",
  //       "GCP",
  //       "Snowflake",
  //       "Fivetran",
  //       "Stitch",
  //       "Python",
  //       "R",
  //       "SQL",
  //       "LookML"
  //     ],
  //     logoUrl: "/logos/bytecode_io_logo.jpeg"
  //   },
  //   {
  //     title: "Senior Data Consultant",
  //     company: "USAA",
  //     period: "May 2019 - Jan 2020",
  //     description: "Enhanced corporate risk assessment capabilities by developing automated tracking systems for over 2M vulnerabilities across four platforms. Created streamlined reporting solutions using Python, SQL, and Tableau to deliver critical insights to executive leadership, enabling data-driven strategy adjustments and improved technology upgrade planning. Implemented more efficient reporting systems that facilitated real-time communication of security patches and responsible staff assignments.",
  //     technologies: [
  //       "Python",
  //       "SQL",
  //       "Excel",
  //       "Tableau"
  //     ],
  //     logoUrl: "logos/usaa_logo.jpeg"
  //   },
  //   {
  //     title: "Data and Analytics Director",
  //     company: "Earth Class Mail",
  //     period: "Oct 2018 - Apr 2019",
  //     description: "Revolutionized data forecasting capabilities while working directly with the CEO and vice presidents to align analytics with business goals. Achieved significant efficiency improvements by reducing month-end reporting time from four days to two hours. Developed and maintained complex forecasting models for 15,000 customers while adapting to new SaaS accounting regulations. Successfully implemented changes that led to measurable ROI improvements across two brands.",
  //     technologies: [
  //       "SaaS",
  //       "Business Intelligence",
  //       "Data Analytics"
  //     ],
  //     logoUrl: "/logos/earth_class_mail_logo.jpeg"
  //   },
  //   {
  //     title: "Senior Data Scientist",
  //     company: "Rackspace",
  //     period: "Aug 2015 - Oct 2018",
  //     description: "Led data science initiatives that drove significant business growth, including a 5% increase in installation-based sales through advanced product recommendation systems using Random Forest methodology. Developed and implemented a customer retention tool incorporating 100+ indicators to assess flight risk, enabling proactive customer retention strategies. Collaborated with Bain Company to analyze survey results from 2,000 C-level industry leaders, providing valuable insights that enhanced marketing campaigns and product roadmaps.",
  //     technologies: [
  //       "Random Forest",
  //       "Machine Learning",
  //       "Statistical Analysis",
  //       "Data Mining",
  //       "Business Intelligence"
  //     ],
  //     logoUrl: "/logos/rackspace_technology_logo.jpeg"
  //   },
  //   {
  //     title: "Marketing Operations Manager",
  //     company: "Rackspace",
  //     period: "Sep 2013 - Aug 2015",
  //     description: "Built and led a new Data Group of six professionals to enhance marketing and sales operations through data-driven insights. Implemented comprehensive reporting solutions that saved sales teams two hours daily and provided unified business visibility across departments. Achieved significant operational efficiencies, including $17,000 in savings and elimination of 40+ monthly man-hours through automation of partner compensation processes. Developed innovative campaign tracking capabilities that were adopted across five additional marketing initiatives. Successfully reversed declining activation rates by leveraging data analysis to guide product team improvements.",
  //     technologies: [
  //       "SQL",
  //       "Dashboard Development",
  //       "Data Analytics",
  //       "Business Intelligence",
  //       "Automation Tools"
  //     ],
  //     logoUrl: "/logos/rackspace_technology_logo.jpeg"
  //   },
  //   {
  //     title: "Marketing Analyst III & IV",
  //     company: "Rackspace",
  //     period: "May 2011 - Sep 2013",
  //     description: "Enhanced Cloud Sales business capabilities during rapid industry expansion by developing comprehensive data solutions across Finance, Marketing, Sales, and Support units. Built a structured data warehouse for Cloud Sales and created an innovative QlikView dashboard for monitoring cloud usage patterns. Achieved remarkable accuracy in revenue forecasting, with projections landing within 10% accuracy two quarters in advance. Implemented real-time usage tracking systems that improved financial forecasting, sales compensation planning, and marketing campaign optimization.",
  //     technologies: [
  //       "QlikView",
  //       "Data Warehousing",
  //       "Cloud Computing",
  //       "Business Analytics",
  //       "Forecasting Models"
  //     ],
  //     logoUrl: "/logos/rackspace_technology_logo.jpeg"
  //   }
  // ]

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <img 
          src="/images/Mark Doucette.jpeg"  
          alt="Mark Doucette"
          className="w-48 h-48 rounded-full mx-auto mb-6 object-cover border-2 border-accent-primary"
        />
        <h1 className="text-4xl font-bold mb-4 text-text-primary">
          Mark Doucette
        </h1>
        <p className="text-xl text-text-secondary max-w-2xl mx-auto mb-8">
          Veteran Data & AI leader transforming businesses through strategic AI solutions. 
          Through TrueSpoke, I architect predictive and generative AI frameworks that scale, 
          specializing in SaaS and Financial Services. I combine strategic vision with proven 
          success in orchestrating high-performing teams to deliver exceptional results.
        </p>
        <div className="flex flex-wrap justify-center gap-4">
          <a 
            href="https://linkedin.com/in/markdoucette"
            target="_blank"
            rel="noopener noreferrer"
            onClick={trackLinkedInClick}
            className="btn btn-primary inline-flex items-center"
          >
            <Linkedin className="mr-2" />
            LinkedIn
          </a>
          <a 
            href="/docs/Mark Doucette Dec 2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
            onClick={trackResumeDownload}
            className="btn btn-secondary inline-flex items-center group"
          >
            <FileText className="mr-2 transition-transform group-hover:scale-110" />
            Download Resume
          </a>
          <Link to="/contact" className="btn btn-secondary inline-flex items-center">
            <Mail className="mr-2" />
            Contact Me
          </Link>
        </div>
      </div>

      {/* Experience Section */}
      
      
      <section className="mb-16">
    <div className="flex justify-between items-center mb-8">
      <h2 className="text-3xl font-bold text-text-primary">
        Professional Experience
      </h2>
      <a 
        href="#recommendations" 
        className="text-accent-primary hover:text-accent-hover flex items-center gap-2"
      >
        View Recommendations
      </a>
    </div>

    {/* Company Carousel */}
    <CompanyCarousel
      companies={companyExperiences}
      selectedCompany={selectedCompany}
      onSelectCompany={setSelectedCompany}
    />

    {/* Accomplishments Display */}
    {selectedCompany && <AccomplishmentsDisplay company={selectedCompany} />}
  </section>
      
      
      
      
      

      {/* Testimonials Section */}
      <section className="mb-16">
  <h2 className="text-3xl font-bold mb-8 text-text-primary">
    Professional Recommendations
  </h2>
  <div className="space-y-8">
    {testimonials.map((testimonial, index) => (
      <div key={index} className="card">
        <div className="flex justify-between items-start mb-6">
          <div className="flex items-center">
            {/* Profile Image */}
            <div className="w-12 h-12 rounded-full overflow-hidden mr-4 border border-background-tertiary">
              {testimonial.imageUrl ? (
                <img 
                  src={testimonial.imageUrl} 
                  alt={testimonial.author}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-background-tertiary flex items-center justify-center">
                  <span className="text-xl font-bold text-accent-primary">
                    {testimonial.author.charAt(0)}
                  </span>
                </div>
              )}
            </div>
            <div>
              <p className="font-semibold text-text-primary">{testimonial.author}</p>
              <p className="text-text-secondary text-sm">{testimonial.title}</p>
              <p className="text-text-secondary text-sm">{testimonial.company}</p>
            </div>
          </div>
          <div className="text-right">
            {testimonial.linkedinUrl && (
              <a
                href={testimonial.linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-accent-primary hover:text-accent-hover inline-flex items-center mb-2"
              >
                <Linkedin className="w-5 h-5" />
              </a>
            )}
            {testimonial.date && (
              <p className="text-text-secondary text-sm">{testimonial.date}</p>
            )}
          </div>
        </div>
        <div className="bg-background-tertiary rounded-lg p-6">
          <p className="text-text-secondary italic leading-relaxed">
            "{testimonial.content}"
          </p>
        </div>
      </div>
    ))}
  </div>
</section>
    </div>
  )
}







