// src/components/Navigation.tsx
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, X } from 'lucide-react'

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen)

  return (
    <nav className="bg-primary text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <img
                className="h-16 w-auto"
                src="logos/truespoke_inverted_transparent.png"
                alt="TrueSpoke - DATA & AI"
              />
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/" className="hover:text-accent">Home</Link>
            <Link to="/about" className="hover:text-accent">About</Link>
            <Link to="/projects" className="hover:text-accent">Projects</Link>
            <Link to="/contact" className="hover:text-accent">Contact</Link>
          </div>

          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-white hover:text-accent"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to="/"
              className="block px-3 py-2 hover:text-accent"
              onClick={toggleMenu}
            >
              Home
            </Link>
            <Link
              to="/about"
              className="block px-3 py-2 hover:text-accent"
              onClick={toggleMenu}
            >
              About
            </Link>
            <Link
              to="/projects"
              className="block px-3 py-2 hover:text-accent"
              onClick={toggleMenu}
            >
              Projects
            </Link>
            <Link
              to="/contact"
              className="block px-3 py-2 hover:text-accent"
              onClick={toggleMenu}
            >
              Contact
            </Link>
          </div>
        </div>
      )}
    </nav>
  )
}