// src/pages/ProjectsPage.tsx
import { Construction, Github } from 'lucide-react'


// import { Construction, Github, ExternalLink } from 'lucide-react'
// import { Link } from 'react-router-dom'

export default function ProjectsPage() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <Construction className="w-16 h-16 text-accent-primary mx-auto mb-6" />
        <h1 className="text-4xl font-bold mb-6 text-text-primary">
          Projects <span className="text-accent-primary">Coming Soon</span>
        </h1>
        <p className="text-text-secondary text-xl max-w-2xl mx-auto mb-8">
          I'm currently documenting my projects and preparing detailed architecture diagrams. 
          While the full documentation is being finalized, you can check out some of my work on GitHub.
        </p>
        <a 
          href="https://github.com/mark-true-spoke" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center bg-accent-primary text-white px-6 py-3 rounded-lg font-semibold hover:bg-accent-hover transition-colors"
        >
          <Github className="mr-2" />
          View GitHub Profile
        </a>
      </div>

      <div className="mt-16">
        <h2 className="text-2xl font-bold mb-6 text-text-primary">What to Expect</h2>
        <div className="grid md:grid-cols-2 gap-8">
          {[
            {
              title: "Detailed Documentation",
              description: "Comprehensive documentation including technology stacks, architecture decisions, and implementation details."
            },
            {
              title: "Architecture Diagrams",
              description: "Visual representations of system designs, data flows, and component interactions."
            },
            {
              title: "GitHub Repositories",
              description: "Reference implementations and code samples where possible, with clear explanations."
            },
            {
              title: "Case Studies",
              description: "Real-world examples of problems solved and solutions implemented."
            }
          ].map((item, index) => (
            <div key={index} className="card">
              <h3 className="text-xl font-semibold mb-2 text-text-primary">{item.title}</h3>
              <p className="text-text-secondary">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}