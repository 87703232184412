// src/pages/ContactPage.tsx
import { useState, FormEvent } from 'react'
import { Mail, MapPin, Phone, Calendar } from 'lucide-react'
import ReactGA from 'react-ga4'
import emailjs from '@emailjs/browser'
import { emailConfig } from '../config/email'



interface ContactForm {
  name: string
  email: string
  message: string
}


export default function ContactPage() {
    // Form state
  const [formData, setFormData] = useState<ContactForm>({
    name: '',
    email: '',
    message: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle')


  // Analytics tracking functions
const trackEmailClick = () => {
  ReactGA.event({
    category: 'Contact',
    action: 'click_email',
    label: 'Email Link'
  });
};

const trackScheduleClick = () => {
  ReactGA.event({
    category: 'Contact',
    action: 'click_schedule',
    label: 'Schedule Meeting',
    value: 1
  });
};

const trackFormSubmit = () => {
  ReactGA.event({
    category: 'Contact',
    action: 'submit_form',
    label: 'Contact Form'
  });
};

const trackFormSuccess = () => {
  ReactGA.event({
    category: 'Contact',
    action: 'form_success',
    label: 'Contact Form'
  });
};

const trackFormError = () => {
  ReactGA.event({
    category: 'Contact',
    action: 'form_error',
    label: 'Contact Form'
  });
};


// Form handlers
const handleSubmit = async (e: FormEvent) => {
  e.preventDefault();
  setIsSubmitting(true);
  setSubmitStatus('idle');
  trackFormSubmit();

  try {
    const result = await emailjs.send(
      emailConfig.serviceId,
      emailConfig.templateId,
      {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
        to_name: 'Mark',
        reply_to: formData.email,
      },
      emailConfig.publicKey
    );


    if (result.status === 200) {
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
      trackFormSuccess();
    } else {
      throw new Error('Failed to send message');
    }
  } catch (error) {
    console.error('Contact form error:', error);
    setSubmitStatus('error');
    trackFormError();
  } finally {
    setIsSubmitting(false);
  }
};

const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  setFormData(prev => ({
    ...prev,
    [e.target.name]: e.target.value
  }));
};



  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Header Section */}
      <div className="mb-16">
        <h1 className="text-4xl font-bold mb-6 text-text-primary">
          Let's <span className="text-accent-primary">Connect</span>
        </h1>
        <p className="text-text-secondary max-w-2xl">
          Schedule a meeting to discuss your project, or send me a message. 
          I'm here to help bring your AI vision to life.
        </p>
      </div>
  
      {/* Two Column Layout */}
      <div className="grid md:grid-cols-2 gap-12">
        {/* Left Column - Contact Options */}
<div className="space-y-8">
{/* Primary Interactive Options */}
<div className="space-y-4">
  {/* Email Card */}
  <a 
    href="mailto:mark@truespoke.io"
    onClick={trackEmailClick}
    className="card flex items-center p-6 hover:bg-background-tertiary transition-all cursor-pointer block group"
  >
    <Mail className="w-10 h-10 text-accent-primary mr-6 flex-shrink-0" />
    <div>
      <h3 className="font-semibold text-text-primary text-lg mb-1">Email</h3>
      <span className="text-text-secondary group-hover:text-accent-primary transition-colors">
        mark@truespoke.io
      </span>
    </div>
  </a>

  {/* Schedule Card */}
  <a
    href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2ANL0J02wTlw-En8CiFn92WjghDg_z0kr4SE_-3HcEbQXAO9JWiBfBlqS9ggui_mG5YGIaFNd1"
    target="_blank"
    rel="noopener noreferrer"
    onClick={trackScheduleClick}
    className="card flex items-center p-6 hover:bg-background-tertiary transition-all cursor-pointer block group"
  >
    <Calendar className="w-10 h-10 text-accent-primary mr-6 flex-shrink-0" />
    <div>
      <h3 className="font-semibold text-text-primary text-lg mb-1">Schedule a Meeting</h3>
      <span className="text-text-secondary group-hover:text-accent-primary transition-colors">
        Book a Meeting
      </span>
    </div>
  </a>
</div>

  {/* Secondary Info */}
  <div className="flex flex-col sm:flex-row gap-4">
    {/* Phone */}
    <div className="flex items-center p-4 bg-background-secondary rounded-lg flex-1">
      <Phone className="w-6 h-6 text-accent-primary mr-4" />
      <div>
        <h3 className="font-semibold text-text-primary mb-1">Phone</h3>
        <p className="text-text-secondary">+1 (210) 284-5852</p>
      </div>
    </div>

    {/* Location */}
    <div className="flex items-center p-4 bg-background-secondary rounded-lg flex-1">
      <MapPin className="w-6 h-6 text-accent-primary mr-4" />
      <div>
        <h3 className="font-semibold text-text-primary mb-1">Location</h3>
        <p className="text-text-secondary">San Antonio, TX</p>
      </div>
    </div>
  </div>
</div>
  
        {/* Right Column - Contact Form */}
        <div className="card">
          <h2 className="text-2xl font-bold text-text-primary mb-6">Send a Message</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-text-secondary mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="input w-full"
              />
            </div>
  
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-text-secondary mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="input w-full"
              />
            </div>
  
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-text-secondary mb-1">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={4}
                className="input w-full"
              />
            </div>
  
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary w-full disabled:opacity-50"
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
  
            {submitStatus === 'success' && (
              <p className="text-state-success">Thank you for your message! We'll be in touch soon.</p>
            )}
            {submitStatus === 'error' && (
              <p className="text-state-error">There was an error sending your message. Please try again.</p>
            )}
          </form>
        </div>



      </div>
    </div>
  );
}




//   return (
//     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//       {/* Header Section */}
//       <div className="text-center mb-16">
//         <h1 className="text-4xl font-bold mb-6 text-text-primary">
//           Let's <span className="text-accent-primary">Connect</span>
//         </h1>
//         <p className="text-text-secondary max-w-2xl mx-auto">
//           Schedule a meeting to discuss your project, or send me a message. 
//           I'm here to help bring your AI vision to life.
//         </p>
//       </div>
  
//       {/* Quick Contact Options */}
//       <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
//         <div className="card flex flex-col items-center p-6 text-center">
//           <Mail className="w-8 h-8 text-accent-primary mb-4" />
//           <h3 className="font-semibold text-text-primary mb-2">Email</h3>
//           <a 
//             href="mailto:mark@truespoke.io" 
//             className="text-text-secondary hover:text-accent-primary transition-colors"
//           >
//             mark@truespoke.io
//           </a>
//         </div>
  
//         <div className="card flex flex-col items-center p-6 text-center">
//           <Phone className="w-8 h-8 text-accent-primary mb-4" />
//           <h3 className="font-semibold text-text-primary mb-2">Phone</h3>
//           <p className="text-text-secondary">+1 (210) 284-5852</p>
//         </div>
  
//         <div className="card flex flex-col items-center p-6 text-center">
//           <MapPin className="w-8 h-8 text-accent-primary mb-4" />
//           <h3 className="font-semibold text-text-primary mb-2">Location</h3>
//           <p className="text-text-secondary">San Antonio, TX</p>
//         </div>
//       </div>
  
//       {/* Scheduling Section */}
//       <div className="mb-16">
//         <div className="flex items-center justify-center mb-8">
//           <Calendar className="w-6 h-6 text-accent-primary mr-3" />
//           <h2 className="text-2xl font-bold text-text-primary">Schedule a Meeting</h2>
//         </div>
//         <div className="rounded-xl overflow-hidden bg-background-calendar shadow-lg">
//           <iframe
//             src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2ANL0J02wTlw-En8CiFn92WjghDg_z0kr4SE_-3HcEbQXAO9JWiBfBlqS9ggui_mG5YGIaFNd1"
//             style={{
//               width: '100%',
//               height: '610px',
//               border: 0,
//             }}
//             frameBorder="0"
//           ></iframe>
//         </div>
//       </div>
  
// {/* Contact Form and Map Section */}
// <div className="grid md:grid-cols-2 gap-12">
//       {/* Contact Form */}
//       <div>
//         <div className="flex items-center mb-6">
//           <Mail className="w-6 h-6 text-accent-primary mr-3" />
//           <h2 className="text-2xl font-bold text-text-primary">Send a Message</h2>
//         </div>
//         <div className="card">
//           <form onSubmit={handleSubmit} className="space-y-6">
//             {/* Contact Form */}
// <div className="card">
//           <form onSubmit={handleSubmit} className="space-y-6">
//             <div>
//               <label htmlFor="name" className="block text-sm font-medium text-text-secondary mb-1">
//                 Name
//               </label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//                 className="input w-full"
//               />
//             </div>

//             <div>
//               <label htmlFor="email" className="block text-sm font-medium text-text-secondary mb-1">
//                 Email
//               </label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//                 className="input w-full"
//               />
//             </div>

//             <div>
//               <label htmlFor="message" className="block text-sm font-medium text-text-secondary mb-1">
//                 Message
//               </label>
//               <textarea
//                 id="message"
//                 name="message"
//                 value={formData.message}
//                 onChange={handleChange}
//                 required
//                 rows={4}
//                 className="input w-full"
//               />
//             </div>

//             <button
//               type="submit"
//               disabled={isSubmitting}
//               className="btn btn-primary w-full disabled:opacity-50"
//             >
//               {isSubmitting ? 'Sending...' : 'Send Message'}
//             </button>

//             {submitStatus === 'success' && (
//               <p className="text-state-success">Thank you for your message! We'll be in touch soon.</p>
//             )}
//             {submitStatus === 'error' && (
//               <p className="text-state-error">There was an error sending your message. Please try again.</p>
//             )}
//           </form>
//         </div>
//           </form>
//         </div>
//       </div>

  
//         {/* Map Section */}
//         <div>
//           <div className="flex items-center mb-6">
//             <MapPin className="w-6 h-6 text-accent-primary mr-3" />
//             <h2 className="text-2xl font-bold text-text-primary">Find Me</h2>
//           </div>
//           <div className="rounded-lg overflow-hidden shadow-lg">
//             <Map />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


//   return (
//     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//       {/* Header */}
//       <h1 className="text-4xl font-bold mb-6 text-text-primary">
//         Get in <span className="text-accent-primary">Touch</span>
//       </h1>
//       <p className="text-text-secondary mb-8 max-w-2xl">
//         Have a question or want to work together? Schedule a meeting or send me a message, 
//         and I'll get back to you as soon as possible.
//       </p>

//       {/* Full Width Scheduling Section */}
//             {/* Schedule Section */}
//             <div className="flex items-start mb-8">
//   <Calendar className="w-6 h-6 text-accent-primary mr-4 flex-shrink-0" />
//   <div className="w-full">
//     <h3 className="font-semibold text-text-primary mb-4">Schedule a Meeting</h3>
//     {/* <div className="rounded-lg bg-white p-4"> White background to help readability */}
//     <div className=" rounded-lg overflow-hidden bg-background-calendar"> {/* White background to help readability */}
   
//       <iframe
//         src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2ANL0J02wTlw-En8CiFn92WjghDg_z0kr4SE_-3HcEbQXAO9JWiBfBlqS9ggui_mG5YGIaFNd1"
//         style={{
//           width: '100%',
//           height: '610px',
//           border: 0,
//           borderRadius: '0.5rem',
//         }}
//         frameBorder="0"
//       ></iframe>
//     </div>
//   </div>
// </div>

//       {/* Two Column Layout for Contact Form and Info */}
//       <div className="grid md:grid-cols-2 gap-12">
//         {/* Contact Info Column */}
//         <div className="space-y-8">
//           {/* Email and Phone */}
//           <div className="space-y-6">
//             <div className="flex items-start">
//               <Mail className="w-6 h-6 text-accent-primary mr-4" />
//               <div>
//                 <h3 className="font-semibold text-text-primary">Email</h3>
//                 <a 
//                   href="mailto:mark@truespoke.io" 
//                   className="text-text-secondary hover:text-accent-primary transition-colors"
//                 >
//                   mark@truespoke.io
//                 </a>
//               </div>
//             </div>

//             <div className="flex items-start">
//               <Phone className="w-6 h-6 text-accent-primary mr-4" />
//               <div>
//                 <h3 className="font-semibold text-text-primary">Phone</h3>
//                 <p className="text-text-secondary">+1 (210) 284-5852</p>
//               </div>
//             </div>
//           </div>

//           {/* Location and Map */}
//           <div className="space-y-6">
//             <div className="flex items-start">
//               <MapPin className="w-6 h-6 text-accent-primary mr-4" />
//               <div>
//                 <h3 className="font-semibold text-text-primary">Location</h3>
//                 <p className="text-text-secondary">San Antonio, TX</p>
//               </div>
//             </div>
            
//             <div className="rounded-lg overflow-hidden shadow-lg">
//               <Map />
//             </div>
//           </div>
//         </div>

        // {/* Contact Form */}
        // <div className="card">
        //   <form onSubmit={handleSubmit} className="space-y-6">
        //     <div>
        //       <label htmlFor="name" className="block text-sm font-medium text-text-secondary mb-1">
        //         Name
        //       </label>
        //       <input
        //         type="text"
        //         id="name"
        //         name="name"
        //         value={formData.name}
        //         onChange={handleChange}
        //         required
        //         className="input w-full"
        //       />
        //     </div>

        //     <div>
        //       <label htmlFor="email" className="block text-sm font-medium text-text-secondary mb-1">
        //         Email
        //       </label>
        //       <input
        //         type="email"
        //         id="email"
        //         name="email"
        //         value={formData.email}
        //         onChange={handleChange}
        //         required
        //         className="input w-full"
        //       />
        //     </div>

        //     <div>
        //       <label htmlFor="message" className="block text-sm font-medium text-text-secondary mb-1">
        //         Message
        //       </label>
        //       <textarea
        //         id="message"
        //         name="message"
        //         value={formData.message}
        //         onChange={handleChange}
        //         required
        //         rows={4}
        //         className="input w-full"
        //       />
        //     </div>

        //     <button
        //       type="submit"
        //       disabled={isSubmitting}
        //       className="btn btn-primary w-full disabled:opacity-50"
        //     >
        //       {isSubmitting ? 'Sending...' : 'Send Message'}
        //     </button>

        //     {submitStatus === 'success' && (
        //       <p className="text-state-success">Thank you for your message! We'll be in touch soon.</p>
        //     )}
        //     {submitStatus === 'error' && (
        //       <p className="text-state-error">There was an error sending your message. Please try again.</p>
        //     )}
        //   </form>
        // </div>
    //   </div>
    // </div>
//   );
// }




//   return (

// <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//       <div className="grid md:grid-cols-2 gap-12">
//         <div>
//           <h1 className="text-4xl font-bold mb-6 text-text-primary">
//             Get in <span className="text-accent-primary">Touch</span>
//             </h1>
//           <p className="text-text-secondary mb-8">
//             Have a question or want to work together? Schedule a meeting or send me a message, 
//             and I'll get back to you as soon as possible.
//           </p>

//       {/* Schedule Section */}
//       <div className="flex items-start mb-8">
//   <Calendar className="w-6 h-6 text-accent-primary mr-4 flex-shrink-0" />
//   <div className="w-full">
//     <h3 className="font-semibold text-text-primary mb-4">Schedule a Meeting</h3>
//     <div className="rounded-lg bg-white p-4"> {/* White background to help readability */}
//       <iframe
//         src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2ANL0J02wTlw-En8CiFn92WjghDg_z0kr4SE_-3HcEbQXAO9JWiBfBlqS9ggui_mG5YGIaFNd1"
//         style={{
//           width: '100%',
//           height: '970px',
//           border: 0,
//           borderRadius: '0.5rem',
//         }}
//         frameBorder="0"
//       ></iframe>
//     </div>
//   </div>
// </div>

//           {/* Email and Phone */}
//           <div className="space-y-6 mb-8">
//             <div className="flex items-start">
//               <Mail className="w-6 h-6 text-accent-primary mr-4" />
//               <div>
//                 <h3 className="font-semibold text-text-primary">Email</h3>
//                 <a 
//                   href="mailto:mark@truespoke.io" 
//                   className="text-text-secondary hover:text-accent-primary transition-colors"
//                 >
//                   mark@truespoke.io
//                 </a>
//               </div>
//             </div>

//             <div className="flex items-start">
//               <Phone className="w-6 h-6 text-accent-primary mr-4" />
//               <div>
//                 <h3 className="font-semibold text-text-primary">Phone</h3>
//                 <p className="text-text-secondary">+1 (210) 284-5852</p>
//               </div>
//             </div>
//           </div>

//           {/* Location and Map */}
//           <div className="space-y-6">
//             <div className="flex items-start">
//               <MapPin className="w-6 h-6 text-accent-primary mr-4" />
//               <div>
//                 <h3 className="font-semibold text-text-primary">Location</h3>
//                 <p className="text-text-secondary">San Antonio, TX</p>
//               </div>
//             </div>
            
//             <div className="rounded-lg overflow-hidden shadow-lg">
//               <Map />
//             </div>
//           </div>
//         </div>

//       {/* Contact Form */}
//         <div className="card">
//           <form onSubmit={handleSubmit} className="space-y-6">
//             <div>
//               <label htmlFor="name" className="block text-sm font-medium text-text-secondary mb-1">
//                 Name
//               </label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//                 className="input w-full"
//               />
//             </div>

//             <div>
//               <label htmlFor="email" className="block text-sm font-medium text-text-secondary mb-1">
//                 Email
//               </label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//                 className="input w-full"
//               />
//             </div>

//             <div>
//               <label htmlFor="message" className="block text-sm font-medium text-text-secondary mb-1">
//                 Message
//               </label>
//               <textarea
//                 id="message"
//                 name="message"
//                 value={formData.message}
//                 onChange={handleChange}
//                 required
//                 rows={4}
//                 className="input w-full"
//               />
//             </div>

//             <button
//               type="submit"
//               disabled={isSubmitting}
//               className="btn btn-primary w-full disabled:opacity-50"
//             >
//               {isSubmitting ? 'Sending...' : 'Send Message'}
//             </button>

//             {submitStatus === 'success' && (
//               <p className="text-state-success">Thank you for your message! We'll be in touch soon.</p>
//             )}
//             {submitStatus === 'error' && (
//               <p className="text-state-error">There was an error sending your message. Please try again.</p>
//             )}
//           </form>
//         </div>
//       </div>
//     </div>
//   )
// }