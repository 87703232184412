// src/App.tsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ProjectsPage from './pages/ProjectsPage'
import ContactPage from './pages/ContactPage'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import emailjs from '@emailjs/browser'
import { emailConfig } from './config/email'



// Initialize GA4
ReactGA.initialize(import.meta.env.VITE_GA4_ID)

export default function App() {
  useEffect(() => {
    // Track page views
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })

    emailjs.init(emailConfig.publicKey)

  }, [])

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Layout>
    </Router>
  )
}