import { Github, Linkedin } from 'lucide-react'

export default function Footer() {
  return (
    <footer className="bg-primary text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <img
              className="h-10 w-auto"
              src="logos/truespoke_inverted_transparent.png"
              alt="TrueSpoke - DATA & AI"
            />
            <p className="mt-2 text-sm">
              Aligning your spokes to the AI revolution
            </p>
          </div>
          
          <div className="flex space-x-6">
            <a href="https://github.com/mark-true-spoke/" target="_blank" className="hover:text-accent">
              <Github size={24} />
            </a>
            <a href="#" className="hover:text-accent">
              <Linkedin size={24} />
            </a>
            {/* <a href="#" className="hover:text-accent">
              <Twitter size={24} />
            </a> */}
          </div>
        </div>
        
        <div className="mt-8 text-center text-sm">
          <p>&copy; {new Date().getFullYear()} True Spoke LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}