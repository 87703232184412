// src/components/BrandName.tsx
export const BrandName = () => (
    <span className="font-michroma inline-flex items-baseline">
      <span className="font-extrabold text-brand-pink">True</span>
      <span className="font-thin">Spoke</span>
    </span>
  );


// export const BrandName = () => (
//     <span className="font-michroma inline-flex items-baseline">
//       <span 
//         className="text-brand-pink"
//         style={{ 
//           fontWeight: 900,
//           fontVariationSettings: "'wght' 900"
//         }}
//       >
//         True
//       </span>
//       <span 
//         style={{ 
//           fontWeight: 100,
//           fontVariationSettings: "'wght' 100"
//         }}
//       >
//         Spoke
//       </span>
//     </span>
//   );
  

// export const BrandName = () => (
// <span className="font-michroma inline-flex items-baseline"> 
//       <span 
//         className="text-brand-pink"
//         style={{ fontWeight: 900 }} // Maximum possible weight
//       >
//         True
//       </span>
//       <span style={{ fontWeight: 100 }}> {/* Minimum possible weight */}
//         Spoke
//       </span>
//     </span>
//   );

// export const BrandName = () => (
//     <span className="font-michroma">
//       <span className="text-brand-pink font-black">True</span>
//       <span className="font-normal">Spoke</span>
//     </span>
//   );

