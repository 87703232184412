// src/components/Layout.tsx
import { ReactNode } from 'react'
import Navigation from './Navigation'
import Footer from './Footer'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen flex flex-col bg-background-primary">
      <Navigation />
      <main className="flex-grow bg-background-primary">
        {children}
      </main>
      <Footer />
    </div>
  )
}